.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 25%); /* Adjust the width of each column */
  grid-auto-rows: 10rem; /* Adjust the height of each row */
  grid-gap: 10px; 
  max-width: 30rem;
  justify-content: center;
}
  
  .grid-item {
    text-align: center;

  }
  .grid-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  