.image-accordion {
    display: flex;
    gap: 10px;
    cursor: pointer;
  }
  
  .image-accordion-item {
    position: relative;
    overflow: hidden;
    width: 90px;
    height: 500px;
    border-radius: 36px;
    display: flex;
    align-items: flex-end;
    opacity: 0.5;
    transition: 0.5s;
  }
  
  .image-accordion-item:hover {
    opacity: 0.75;
  }
  
  .image-accordion :is(h2, p) {
    margin: 0;
  }
  
  .image-accordion-item h2 {
    font-size: 24px;
    font-weight: 400;
    color: rgb(255 255 255 / 96%);
  }
  
  .image-accordion-item p {
    color: rgb(255, 255, 255);
  }
  
  .image-accordion-item.active {
    width: 40rem;
    opacity: 1;
  }
  
  .image-accordion-item .material-symbols-outlined {
    display: grid;
    place-items: center;
    width: 50px;
    height: 50px;
    color: rgb(0 0 0 / 80%);
    background: rgb(255, 255, 255);
    border-radius: 50%;
    font-size: 28px;
  }
  
  .image-accordion-item .content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    padding: 100px 0 20px 20px;
    display: flex;
    align-items: center;
    gap: 14px;
    background: linear-gradient(to bottom, rgb(0 0 0 / 0%), rgb(0 0 0 / 80%));
    transition: 0.25s;
  }
  
  .image-accordion-item.active .content {
    opacity: 1;
    visibility: visible;
  }
  
  .image-accordion-item img {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    height: 100%;
  }

  @media (max-width: 600px) {
    .image-accordion {
      flex-direction: column; /* Change to column layout on mobile */
    }
    
    .image-accordion-item {
      width: 100%; /* Expand item to full width */
      height: 50px; /* Adjust height as needed */
      align-items: center; /* Align items horizontally */
    }
  
    .image-accordion-item.active {
      width: auto; /* Let width adjust automatically based on content */
      height: 16rem; /* Expand vertically for active state */
      opacity: 1; /* Retain opacity for active state */
    }
  
    .image-accordion-item .content {
      position: relative; /* Change to relative positioning */
      width: 100%; /* Expand content to full width */
      padding: 1%;
      height: 100%;
      padding-left: 7%;
      padding-top: 42%;
    }
  
    .image-accordion-item img {
      width: 25rem; /* Ensure the image fills the container */
      height: auto; /* Let the height adjust automatically */
      border: 0; /* Remove any border */
    }

    .image-accordion-item.active img {
      transform: scale(0.9);
    }

    .image-accordion-item.active .content p{
      padding-bottom: 3%;
    }
    
    #geralt{
      top: 40vw;
    }


  }