body{
  justify-content: center;
}

.formCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25rem; 
  max-width: 400px;
  padding: 5% 5%; 
  background: #452c63;
  border-radius: 5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center horizontally */
}

.formCard form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.formName,
.formEmail,
.formMessage {
  text-align: center;
  margin-bottom: 3%; 
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 2% 3%; 
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em; 
}

.formBtn {
  display: inline-block;
  padding: 5%;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #03C03C;
  border: none;
  border-radius: 15px;
  margin-top: 5%;
}

.formBtn:hover {
  background-color: #228B22
}
.formBtn:active {
  background-color: #228B22;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

@media (max-width: 600px) {
  .formCard {
    width: 15rem;
    padding: 10%; 
    justify-content: center; /* Add this line */
    align-items: center; /* Add this line */
    display: flex; /* Add this line */
  }

  input[type="text"],
  input[type="email"],
  textarea,
  .formBtn {
    padding: 3% 8%; 
  }

  .formName,
  .formEmail,
  .formMessage {
    margin-bottom: 5%;
  }
}